import { LessonSubmissionResponse } from "shared/types/moduleTypes"
import { Dispatch, SetStateAction } from "react"
import modules from "shared/routes/moduleRoutes"
import { TeacherAssignmentResponse } from "shared/types/teacherTypes"
import pureInquiry, { PutQuestionProps } from "shared/routes/simulations/pureInquiry"
import LessonResponseItem from "./LessonResponseItem"

interface Props {
    lessonSubmission: LessonSubmissionResponse
    setLessonSubmission: Dispatch<SetStateAction<LessonSubmissionResponse>>
    sumPoints: (lessonSummary: LessonSubmissionResponse) => number
    lesson: any
    assignment: TeacherAssignmentResponse
    studentLessonId: string
    expand: boolean
    setExpand: Dispatch<SetStateAction<boolean>>
    collapsible: boolean
}

export interface UpdateQuestionProps {
    section: number
    questionNumber: number
    grade?: number
    comment?: string
}

const TeacherLessonResponses = ({ lessonSubmission, setLessonSubmission, sumPoints, lesson, assignment, studentLessonId, expand, setExpand, collapsible } : Props) => {

    const embeddedQuestionCount = lessonSubmission?.responses?.findIndex((response) => response.section === 2) - 1

    const updateQuestion = ({ section, questionNumber, grade = undefined, comment = undefined }: UpdateQuestionProps) => {
        if (assignment.lessonVariety === "Guided") {
            const body: { studentLessonId: string, section: number, questionNumber: number, grade?: number, comment?: string } = { section, studentLessonId, questionNumber }
            if (grade !== undefined) {
                body.grade = grade
            }
            if (comment !== undefined) {
                body.comment = comment
            }
            return modules.updateQuestionGrade({
                moduleName: lesson.module,
                lessonType: lesson.type,
                lessonNumber: lesson?.number.toString(),
                body
            })
        } else if (assignment.lessonVariety === "Pure Inquiry") {
            const body: Partial<PutQuestionProps> = {
                pureInquiryId: studentLessonId,
                questionNumber,
            }
            if (grade !== undefined) {
                body.grade = grade
            }
            if (comment !== undefined) {
                body.comment = comment
            }
            return pureInquiry.putQuestion(body as PutQuestionProps)
        }
    }

    if (lessonSubmission === null) return <></>
    return <>
        <div className="d-flex justify-content-center">
            <h3 className={`flex-grow-1 text-center fw-bold ${expand && `mb-4`}`}>Lesson Responses</h3>
            {collapsible &&
                <i role="button" className={`fas ${expand ? `fa-chevron-up` : `fa-chevron-down`} ms-auto`} onClick={() => setExpand(prev => !prev)} />
            }
        </div>
        {expand &&
            <ol className="p-4">
                {lessonSubmission.responses?.map((response, index) => 
                    <LessonResponseItem
                        index={index}
                        responseType="lesson"
                        embeddedQuestionCount={embeddedQuestionCount}
                        submission={lessonSubmission}
                        response={response}
                        setSubmission={setLessonSubmission}
                        updateQuestion={updateQuestion}
                        sumPoints={sumPoints}
                    />
                )}
            </ol>
        }
    </>
}

export default TeacherLessonResponses
