import { AuthContext } from 'AuthContext'
import styles from "../../components/Assignment/styles.module.scss"
import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { AssignmentContext } from 'components/Assignment/AssignmentContext'
import TopicBody from '../Curriculum/TopicBody'
import AssignmentProgress from 'components/Assignment/AssignmentProgress'
import { toast } from 'react-toastify'
import { saveTopic } from 'components/Lessons/LessonModule/helpers/lessonHelper'

function AssignmentTopic({ topicId, moduleName, submissionId, requireCompletion, preSubmit, hasTopicAssignment }) {
    const authContext = React.useContext(AuthContext)
    const { setActivePart } = React.useContext(AssignmentContext)
    const [submitting, setSubmitting] = useState(false)
    const topicFormRef = React.useRef(null)
    const [studentAnswers, setStudentAnswers] = useState({})
    const startTimeRef = React.useRef(new Date().toISOString().slice(0, 19).replace('T', ' '))

    const isTeacher = authContext.isLoggedIn && !authContext.isStudent

    const onSubmit = async () => {
        setSubmitting(true)
        saveTopic({ topicId, studentAnswers, submissionId, startTime: startTimeRef.current })
            .then(() => {
                toast.success("Topic Submitted.")
            })
            .catch(err => {
                setSubmitting(false)
                toast.error(err?.response?.data?.message ?? "There was an error submitting the lesson. Please try again.")
                return false 
            })
            .finally(() => {
                setSubmitting(false)
            })
        return true
    }

    return (<>
        <Row className={`${styles.assignmentContents}`}>
            <TopicBody
                topicFormRef={topicFormRef}
                topicId={topicId}
                studentAnswers={studentAnswers}
                setStudentAnswers={setStudentAnswers}
            />
        </Row>
        <Row className={`${styles.assignmentProgress} my-3`}>
            <Col sm={12} xl={8} className={`${styles.fitContent} m-auto`}>
                <AssignmentProgress hasTopicAssignment={hasTopicAssignment} active="topic" />
            </Col>
            <Col sm={12} xl={4} className={`text-end ${styles.fitContent} d-flex justify-content-center`}>

                <Button
                    variant="outline-theme"
                    className="btn-lg me-3"
                    disabled={submitting}
                    onClick={() => {
                        if (preSubmit) {
                            preSubmit(onSubmit, topicFormRef)
                        } else {
                            onSubmit()
                        }
                    }}>
                    {isTeacher ? "View Lesson" : "Submit Topic"}
                </Button>
                {hasTopicAssignment && isTeacher &&
                    <Button
                        variant="outline-theme"
                        className="btn-lg me-3"
                        disabled={submitting}
                        onClick={() => setActivePart("assessment")}
                    >
                        View Assessment
                    </Button>
                }
            </Col>
        </Row>
    </>
    )
}

export default AssignmentTopic
