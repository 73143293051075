import { SubmissionResponse } from "shared/types/moduleTypes"
import CustomHTML from "components/CustomHTML"
import Pane from "components/Dashboards/General/Pane"

interface Props {
    index: number
    response: SubmissionResponse
}

const AssessmentResponseItem = ({ index, response } : Props) => {
    const correct: boolean = response.answer === response.correctAnswer
    
    return (<Pane className="mb-4 shadow-sm">
        <div className="text-start" key={`${index}`}>
            <span className={"pe-2 " + (response.answer ? (correct ? "text-success" : "text-danger"): "text-secondary")}>
                <span className="fw-bold">{response.questionNumber ?? (index + 1)}.</span> <span dangerouslySetInnerHTML={{ __html: response.question }} />
                {response.answer && <i className={`fas ${correct ? `fa-check text-success` : `fa-times text-danger`} ms-2`} />}
            </span>
            <br /><br />
            {response.answer && 
                <div className="mb-2"><strong>Student's Answer: </strong><CustomHTML html={response.answer} /></div>
            }
            {/* Only show the correct answer if the student provided an incorrect answer */}
            {!correct && response.correctAnswer &&
                <div className="mb-2"><strong>Correct Answer: </strong><CustomHTML html={response.correctAnswer} /></div>
            }
        </div>
    </Pane>)
}

export default AssessmentResponseItem
