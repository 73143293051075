import CustomHTML from 'components/CustomHTML'
import styles from "../studentSubmission.module.scss"
import { Col, Row } from 'react-bootstrap'
import { TopicQuestionResponse } from 'shared/types/moduleTypes'

interface Props {
    completed: string
    index: number
    response: TopicQuestionResponse
}

const LessonResponseItem = ({ response, completed, index } : Props) => {
    return (<div className="text-start" key={`${completed} ${index}`}>
        <span className="pe-2"><span className="fw-bold">{response.questionNumber}.</span> <CustomHTML html={response.question} /></span>
        <br />
        <Row>
            <Col sm={4}>
                <p className="mb-2"><strong>Grade</strong></p>
                {response.grade ?? 0} / 10
            </Col>
            <Col sm={8}>
                <p className="mb-2"><strong>Your Answer</strong></p>
                <p style = {{
                    minHeight: ""
                }}>
                    {response.answer}
                    {/*
                        In case the student answer is blanks, we need a none-breaking space 
                        here so the height of the paragraph tag is always rendered.
                        */}
                    &nbsp;
                </p>
            </Col>
            <Col sm={12}>
                {/* Only show the correct answer if one exists. A blank box would be confusing */}
                {response.correctAnswer && <>
                    <p className="mb-2"><strong>Correct Answer</strong></p>
                    <p>
                        <CustomHTML html={response.correctAnswer} />
                    </p>
                </>}
            </Col>
        </Row>
        <Row>
            <Col>
                <p className="mb-2"><strong>Comments</strong></p>
                <textarea
                    className={`${styles.commentTextbox} w-100 mb-3`}
                    defaultValue={response.comment ?? ""}
                    disabled={true}
                />
            </Col>
        </Row>
    </div>)
}

export default LessonResponseItem
