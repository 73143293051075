import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { ClassResponse, StudentResponse } from "../../../../shared/types/teacherTypes"
import LessonSearch from "../../../Lessons/Search/Root"
import Button from "react-bootstrap/Button"
import { LessonResponse } from "shared/lessons"
import { classGradeRange } from "shared/types/moduleTypes"
interface Props {
    activeClass: ClassResponse
}

export default function AssignmentSearch(props: Props) {
    const navigate = useNavigate()

    const onAssign = (lesson: LessonResponse, extraParams?) => {
        navigate("/dashboard/teacher/assignments/add", {
            state: {
                lessonId: lesson.id,
                lessonVariety: lesson.variety,
                module: lesson.moduleName,
                type: lesson.type,
                assessment: lesson.assessment,
                topicId: lesson.topicId,
                ...extraParams
            }
        })
    }

    const onAssignLesson = (lesson: LessonResponse, selectedStudent: StudentResponse) => {
        onAssign(lesson, { assignmentType: "lesson", selectedStudent })
    }
    const onAssignAssessment = (lesson: LessonResponse, selectedStudent: StudentResponse) => {
        onAssign(lesson, { assignmentType: "assessment", selectedStudent })
    }
    const onAssignTopic = (lesson: LessonResponse, selectedStudent: StudentResponse) => {
        onAssign(lesson, { assignmentType: "topic", selectedStudent })
    }
    const lessonBtn = (lesson: LessonResponse, selectedStudent: StudentResponse) => <>
        <Button variant="theme float-end me-2" onClick={() => onAssignLesson(lesson, selectedStudent)}>View/Assign Lesson</Button>
        {lesson.assessmentNumber && <Button variant="theme float-end me-2" onClick={() => onAssignAssessment(lesson, selectedStudent)}>View/Assign Assessment</Button>}
        {lesson.topicId && <Button variant="theme float-end me-2" onClick={() => onAssignTopic(lesson, selectedStudent)}>View/Assign Topic</Button>}
    </>
    return (
        <div className="row">
            <div className="text-start mb-3">
                <Link style={{
                    "textDecoration": "none"
                }} to="/dashboard/teacher/assignments"><i className="fas fa-arrow-left" /> Return to Assignments</Link>
            </div>
            <LessonSearch
                activeClass={props.activeClass}
                onLessonSelection={lessonBtn}
                selectedGradeInit={classGradeRange[props.activeClass.gradeLevel]}
                initStandardId={props.activeClass.standardId}
                viewType="assignment"
            />
        </div>
    )
}
