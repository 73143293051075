import { useContext } from 'react'
import { Field } from 'formik'
import styles from "../../../components/Lessons/LessonModule/styles.module.scss"
import assignmentStyles from "../../../components/Assignment/assessment.module.scss"
import { AuthContext } from "AuthContext"
import { CurriculumQuestion } from 'shared/types/curriculumTypes'

interface SectionQuestionsProps {
    questions: CurriculumQuestion[]
    sectionNumber: number
    studentAnswers: any
    handleAnswerChange: any
}

function SectionQuestions({ questions, sectionNumber, studentAnswers, handleAnswerChange } : SectionQuestionsProps) {
    const authContext = useContext(AuthContext)
    const isTeacher = authContext.isLoggedIn && !authContext.isStudent

    return (
        <ol className='list-group list-group-flush text-start'>
            {questions?.map((question, index) => (
                <li key={index} className='list-group-item'>
                    <h6 className='fw-bold'>{index + 1}. <span dangerouslySetInnerHTML={{ __html: question.question }}></span></h6>
                    {question?.answers?.length > 1 ? question?.answers?.map((answer, answerIndex) => (
                        <div
                            key={answerIndex}
                            className={`${assignmentStyles.answerOption} text-start`}
                        >
                            <input
                                type="radio"
                                id={`s${sectionNumber}-q${question.sortOrder}a${answerIndex}`}
                                name={`s${sectionNumber}-q${question.sortOrder}`}
                                value={answer.letter}
                                checked={studentAnswers[`s${sectionNumber}-q${question.sortOrder}`]?.answer === answer.letter}
                                onChange={() => handleAnswerChange(sectionNumber, question.sortOrder, answer.letter)}
                            />
                            <label htmlFor={`s${sectionNumber}-q${question.sortOrder}a${answerIndex}`} className="ms-1">
                                <span className='me-2'>{answer.letter}.</span>
                                <span>{answer.answer}</span>
                            </label>
                        </div>
                    ))
                        : <Field
                            as="textarea"
                            className={`${styles.lessonInput} input-glow ms-auto`}
                            name={`s${sectionNumber}-q${question.sortOrder}`}
                            value={studentAnswers[`s${sectionNumber}-q${question.sortOrder}`]?.answer}
                            onChange={(e) => handleAnswerChange(sectionNumber, question.sortOrder, e.target.value)}
                        />
                    }
                    {isTeacher && <strong className='text-success d-block mt-2'>Correct Answer: <span dangerouslySetInnerHTML={{ __html: question.correctAnswer}} /></strong>}
                </li>
            ))}
        </ol>
    )
}

export default SectionQuestions
