import * as React from "react"
import AssignmentLesson from "./AssignmentLesson"
import Assessment from "./AssignmentAssessment"
import { toast, ToastContainer } from "react-toastify"
import style from "./styles.module.scss"
import * as modules from "../../models/modules"
import sharedModules from "shared/routes/moduleRoutes"
import { LessonVariety, Type } from "shared/types/moduleTypes"
import "react-toastify/dist/ReactToastify.css"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import useBoolean from "helpers/useBoolean"
import { BeatLoader } from "react-spinners"
import assignments from "shared/routes/assignments"
import pureInquiry from "shared/routes/simulations/pureInquiry"
import { AssignmentContext } from "./AssignmentContext"
import { AuthContext } from "AuthContext"
import CustomModal from "components/Modals/CustomModal"
import { parseFormData, formHasSufficientData } from "helpers/formDataHelper"
import AssignmentTopic from "components/Assignment/AssignmentTopic"

interface AssignmentComponentProps {
    topicId?: string
    assessmentId?: string
    videoId?: string
    assessmentNumber?: number
    submissionId?: string
    moduleName: string
    lessonVariety: LessonVariety
    type: Type,
    goBack?: () => void
    requireCompletion?: boolean
    continueAssessment?: boolean
}

export default function AssignmentComponent({ videoId = null, assessmentNumber = null, submissionId, moduleName, lessonVariety, type, goBack,
    requireCompletion, continueAssessment, assessmentId, topicId }: AssignmentComponentProps) {
    const assignmentContext = React.useContext(AssignmentContext)
    const authContext = React.useContext(AuthContext)
    const { activePart, setActivePart, restartAssignment } = assignmentContext
    const [activeVideoId, setActiveVideoId] = React.useState(videoId)

    const [initialLoading, setInitialLoading] = React.useState(true)
    const [initialLessonData, setInitialLessonData] = React.useState<Record<string, string>>(null)
    const [initialPureInquiryData, setInitialPureInquiryData] = React.useState<{ questionAnswers: string[], tableAnswers: string[] }>(null)

    const [resumeModalVisible, setResumeModalVisible] = React.useState(false)
    const [finishLessonModalVisible, toggleFinishLessonModalVisible] = useBoolean(false)
    const [lessonCompleteConfirmModal, toggleLessonCompleteConfirmModal] = useBoolean(false)
    const [topicCompleteConfirmModal, toggleTopicCompleteConfirmModal] = useBoolean(false)
    const [continueAssessmentModalVisible, setContinueAssessmentModalVisible] = React.useState<boolean>(continueAssessment)
    const [lessonSubmitFn, setLessonSubmitFn] = React.useState<() => Promise<boolean>>(null)
    const [topicSubmitFn, setTopicSubmitFn] = React.useState<() => Promise<boolean>>(null)
    const [finishTopicModalVisible, toggleFinishTopicModalVisible] = useBoolean(false)

    const isTeacher = authContext.isLoggedIn && !authContext.isStudent

    React.useEffect(() => {
        if (submissionId) {
            assignments.getSubmission({ submissionId }).then(async ({ data: submission }) => {
                if (lessonVariety === "Guided" && submission.studentLessonId) {
                    const response = await sharedModules.getLessonSubmission({
                        moduleName: moduleName,
                        lessonType: type,
                        lessonNumber: assignmentContext.guidedLesson?.number,
                        studentLessonId: submission.studentLessonId
                    })

                    const lessonData = {}
                    response.responses?.forEach((answer, index) => {
                        lessonData[`question-${answer.section}-${answer.questionNumber}`] = answer.answer
                    })
                    const rawTableData: { Response: string, ID: number }[] = JSON.parse(response.tableData ?? "[]")
                    rawTableData.forEach(answer => {
                        lessonData[`table-${answer.ID}`] = answer.Response
                    })
                    setInitialLessonData(lessonData)
                } else if (lessonVariety === "Pure Inquiry" && submission.studentPureInquiryId) {
                    const { data: response } = await pureInquiry.get({
                        pureInquiryId: submission.studentPureInquiryId
                    })
                    setInitialPureInquiryData({
                        questionAnswers: response.questionAnswers,
                        tableAnswers: response.tableAnswers
                    })
                }
            }).finally(() => setInitialLoading(false))
        }
        else {
            setInitialLoading(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionId])

    React.useEffect(() => {
        if (activeVideoId === null && moduleName) {
            void modules.getVideoID(moduleName)
                .then(({videoPath}) => setActiveVideoId(videoPath))
        }
    }, [moduleName, activeVideoId])

    const resumingLesson =
        (initialLessonData && Object.keys(initialLessonData).length > 0) ||
        (initialPureInquiryData && (
            initialPureInquiryData.questionAnswers.length > 0 ||
            initialPureInquiryData.tableAnswers.length > 0
        ))

    React.useEffect(() => {
        setResumeModalVisible(!!goBack && resumingLesson && !continueAssessment)
        setContinueAssessmentModalVisible(continueAssessment)
    }, [goBack, resumingLesson, continueAssessment])

    if (initialLoading) {
        return <>
            <ToastContainer />
            <BeatLoader />
        </>
    }

    const navigateAssessmentOrComplete = (assessmentNumber: number) => {
        if (assessmentNumber) {
            setActivePart("assessment")
        } else {
            setActivePart("completed")
        }
    }

    const restartLesson = () => {
        setInitialLessonData({})
        setInitialPureInquiryData({ questionAnswers: [], tableAnswers: [] })
    }

    return <>
        <ToastContainer />
        {activePart === "lesson" && <>
            <Modal show={resumeModalVisible} centered size="lg">
                <Modal.Header>
                    <Modal.Title className="w-100 fw-bold">Resume Lesson</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Your answers from previous attempt have been restored. You can either resume or restart the lesson. As you fill out this lesson, your answers will be automatically saved.</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={goBack}>Go Back</Button>
                    <Button variant="secondary" onClick={restartLesson}>Restart <span className="d-none d-sm-inline">Lesson</span></Button>
                    <Button variant="theme" onClick={() => setResumeModalVisible(false)}>Resume <span className="d-none d-sm-inline">Lesson</span></Button>
                </Modal.Footer>
            </Modal>

            <AssignmentLesson
                hasTopicAssignment={topicId !== null}
                submissionId={submissionId}
                moduleName={moduleName}
                type={type}
                videoId={activeVideoId}
                hasAssessment={!!assessmentNumber}
                initialLessonData={initialLessonData}
                initialPureInquiryData={initialPureInquiryData}
                lessonVariety={lessonVariety}
                preSubmit={(submitFn, lessonFormRef) => {
                    if (isTeacher) {
                        navigateAssessmentOrComplete(assessmentNumber)
                    } else {
                        if (goBack && assessmentNumber) {
                            if (requireCompletion) {
                                const answerFields = [...document.querySelectorAll("textarea")].filter(node => {
                                    return node.name.match(/^question|^table/)
                                })
                                if (answerFields.some(node => node.value.length < 1)) {
                                    toast.error("Please fill out the entire lesson before submitting.")
                                    return
                                }
                            }

                            const parsedFormData = parseFormData(lessonFormRef.current)
                            const formHasData = formHasSufficientData(parsedFormData)

                            if (formHasData) {
                                submitFn().then(success => {
                                    if (success) {
                                        toggleFinishLessonModalVisible()
                                    }
                                })
                            } else {
                                setLessonSubmitFn(() => submitFn)
                                toggleLessonCompleteConfirmModal()
                            }
                        }
                    }
                }}
            />

            <Modal show={finishLessonModalVisible} centered size="lg">
                <Modal.Header>
                    <Modal.Title className="w-100">Lesson Submitted</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>After clicking "Begin Assessment", you will start the assessment for this lesson. You will not be able to resume the assessment, so make sure you have adequate time to complete it before starting the assessment. We recommend having 15 minutes available.</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={goBack}>Go Back to Assignments</Button>
                    <Button variant="theme" onClick={() => navigateAssessmentOrComplete(assessmentNumber)}>Begin Assessment</Button>
                </Modal.Footer>
            </Modal>

            <CustomModal
                showModal={lessonCompleteConfirmModal}
                header="Submit Lesson"
                body="Are you sure you want to submit your lesson now? Have you completed the Tables and Questions associated with this lesson?"
                onConfirm={() => {
                    toggleLessonCompleteConfirmModal() //hide lesson complete confirmation modal first
                    lessonSubmitFn().then((success) => {
                        if (success) {
                            toggleFinishLessonModalVisible()
                        }
                    })
                }}
                onDecline={toggleLessonCompleteConfirmModal}
                confirmBtnText="Yes"
                declineBtnText="No"
            />

            <CustomModal
                showModal={continueAssessmentModalVisible}
                header="Continue Assessment"
                body="Do you want to complete the assessment from previous assignment submission?"
                onConfirm={() => {
                    // continue assessment from previous submission
                    setContinueAssessmentModalVisible(false)
                    setActivePart("assessment")
                }}
                onDecline={async () => {
                    // restart assignment
                    restartLesson()
                    await restartAssignment()
                    setActivePart("completed") // this is needed to re-render this entire component so that new submission id is used
                    setActivePart("lesson")
                }}
                confirmBtnText="Continue"
                declineBtnText="Restart Assignment"
            />
        </>}
        {activePart === "assessment" && assessmentNumber &&
            <Assessment
                hasTopicAssignment={topicId !== null}
                assessmentId={assessmentId}
                submissionId={submissionId}
                moduleName={moduleName}
                requireCompletion={requireCompletion}
                assessmentNumber={assessmentNumber}
                type={type}
                submitted={() => {
                    if (isTeacher) {
                        setActivePart("lesson")
                    } else {
                        setActivePart("completed")
                    }
                }}
            />
        }

        {activePart === "topic" &&
            <>
                <Modal show={finishTopicModalVisible} centered size="lg">
                    <Modal.Header>
                        <Modal.Title className="w-100">Topic Submitted</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>After clicking "Begin Lesson", you will start the lesson for this topic.</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={goBack}>Go Back to Assignments</Button>
                        <Button variant="theme" onClick={() => setActivePart("lesson")}>Begin Lesson</Button>
                    </Modal.Footer>
                </Modal>

                <CustomModal
                    showModal={topicCompleteConfirmModal}
                    header="Submit Topic"
                    body="Are you sure you want to submit your topic now? Have you completed all Questions associated with this topic?"
                    onConfirm={() => {
                        toggleTopicCompleteConfirmModal() //hide lesson complete confirmation modal first
                        topicSubmitFn().then((success) => {
                            if (success) {
                                toggleFinishTopicModalVisible()
                            }
                        })
                    }}
                    onDecline={toggleTopicCompleteConfirmModal}
                    confirmBtnText="Yes"
                    declineBtnText="No"
                />
                <AssignmentTopic
                    submissionId={submissionId}
                    hasTopicAssignment={topicId !== null}
                    requireCompletion={requireCompletion}
                    moduleName={moduleName}
                    topicId={topicId}
                    preSubmit={(submitFn, topicFormRef) => {
                        if (isTeacher) {
                            // Directly change the active part for teachers
                            if (activePart === "topic") {
                                setActivePart("lesson")
                            } else if (activePart === "lesson") {
                                setActivePart("assessment")
                            } else {
                                setActivePart("completed")
                            }
                        } else {
                            // Handle form validation and submission for non-teachers
                            if (requireCompletion) {
                                // Ensure all required fields are filled
                                const answerFields = [...document.querySelectorAll("textarea")].filter(node => node.name.match(/^question|^table/))
                                if (answerFields.some(node => node.value.length < 1)) {
                                    toast.error("Please fill out the entire lesson before submitting.")
                                    return
                                }
                            }

                            // Validate form data
                            const parsedFormData = parseFormData(topicFormRef.current)
                            const validFormData = formHasSufficientData(parsedFormData)

                            if (validFormData) {
                                submitFn().then(success => {
                                    if (success) {
                                        toggleFinishTopicModalVisible()
                                    }
                                })
                            } else {
                                // Show confirmation modal if data is not sufficient
                                setTopicSubmitFn(() => submitFn)
                                toggleTopicCompleteConfirmModal()
                            }
                        }
                    }}
                />
            </>
        }

        {
            activePart === "completed" &&
            <div className={`text-center ${style.assignmentCompleted}`}>
                <h2>Completed Lesson</h2>
            </div>
        }
    </>
}
