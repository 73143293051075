import Pane from "components/Dashboards/General/Pane"
import { AssessmentSubmissionResponse } from "shared/types/moduleTypes"
import AssessmentResponseItem from "./AssessmentResponseItem"
import useBoolean from "helpers/useBoolean"

interface Props {
    assessmentSubmission: AssessmentSubmissionResponse
    collapsible: boolean
}

const TeacherAssessmentResponses = ({ assessmentSubmission, collapsible } : Props) => {

    const [expand, toggleExpand] = useBoolean(true)

    if (!assessmentSubmission) return <></>
    return <Pane className="my-3 shadow">
        <div className="d-flex justify-content-center">
            <h3 className={`flex-grow-1 text-center fw-bold ${expand && `mb-4`}`}>Assessment Responses</h3>
            {collapsible && 
                <i role="button" className={`fas ${expand ? `fa-chevron-up` : `fa-chevron-down`} ms-auto`} onClick={toggleExpand} />
            }
        </div>
        {expand &&
            <ol className="p-4">
                {assessmentSubmission.responses?.map((response, index) => 
                    <AssessmentResponseItem
                        index={index}
                        response={response}
                    />)}
            </ol>
        }
    </Pane>
}

export default TeacherAssessmentResponses
