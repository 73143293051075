import { TopicSubmissionResponse } from 'shared/types/moduleTypes'
import LessonResponseItem from './LessonResponseItem'
import { AssessmentViewOptionsType } from 'shared/types/assessmentTypes'
import AssessmentResponseItem from './AssessmentResponseItem'

interface Props {
    topicSubmission: TopicSubmissionResponse
    assessmentViewOption: AssessmentViewOptionsType
}

const StudentTopicResponses = ({ topicSubmission, assessmentViewOption } : Props) => {
  return (
    <>
        <h2 className="h3 text-start pb-3 fw-bold text-center">Topic Responses</h2>
        <ol className="mt-3">
            {topicSubmission.responses?.map((response, index) => {
                return (
                    response.questionType === "Text" ? 
                        <LessonResponseItem response={response} completed={topicSubmission.completed} index={index} /> :
                        <AssessmentResponseItem response={response} completed={topicSubmission.completed} index={index} assessmentViewOption={assessmentViewOption} />
                )
            })}
        </ol>
    </>
  )
}

export default StudentTopicResponses
