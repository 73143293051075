import { Formik, Field } from 'formik'
import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import styles from "../../components/Lessons/LessonModule/styles.module.scss"
import topics from 'shared/routes/topics/topics'
import { useModel } from '@stem-sims/nexus'
import Loader from 'react-spinners/BeatLoader'
import SectionQuestions from './Sections/SectionQuestions'

interface TopicBodyProps {
    topicId: string
    topicFormRef: any
    studentAnswers: any
    setStudentAnswers: any
}

function TopicBody({ topicId, topicFormRef, studentAnswers, setStudentAnswers } : TopicBodyProps) {
    const formikRef = React.useRef<any>()

    const startTime = new Date().toISOString().slice(0, 19).replace('T', ' ')

    const { response: topic, loading: loadingTopic } = useModel({
        model: topics.findById,
        props: { id: topicId }
    })

    // Handle answer selection
    const handleAnswerChange = (sectionNum: number, questionsNum: number, answer: string) => {
        setStudentAnswers(prev => ({
            ...prev, 
            [`s${sectionNum}-q${questionsNum}`]: {...prev[`s${sectionNum}-q${questionsNum}`], answer: answer}
        }))
    }

    useEffect(() => {
        // initialize the object with essential data such as question id and type to send to backend
        const initialStudentAnswers = {}
        topic?.sections?.forEach(section => {
            section?.questions?.forEach(question => {
                initialStudentAnswers[`s${section.sortOrder}-q${question.sortOrder}`] = {topicQuestionId: question.id, answer: "", questionType: question.type}
            })
        })
        setStudentAnswers(initialStudentAnswers)
    }, [topic, setStudentAnswers])

    return (
        <>
            {loadingTopic ?
                <Loader color={"#007bff"} /> :
                <Formik initialValues={{ ...{}, started: startTime }} onSubmit={() => { }} innerRef={formikRef}>
                    {({ isSubmitting }) =>
                        <Form ref={topicFormRef} className={`${styles.lessonTabs} h-100`}>
                            {topic?.sections && topic.sections.length > 0 ? (
                                topic.sections.map((section, index) => {
                                    if (index !== 1 && index !== 2) {
                                        return (
                                            <div className="container mt-4" key={index}>
                                                <h1>{section?.header}</h1>
                                                <div className="text-start" dangerouslySetInnerHTML={{ __html: section?.sectionText }} />
                                                <SectionQuestions handleAnswerChange={handleAnswerChange} studentAnswers={studentAnswers} questions={section?.questions} sectionNumber={section.sortOrder} />
                                            </div>
                                        )
                                    }
                                    return null
                                })
                            ) : (
                                <p>No sections available.</p>
                            )}
                            <Field type="hidden" name="started" />
                        </Form>}
                </Formik>}
        </>
    )
}

export default TopicBody
