import Label from "components/General/Label"
import { Dispatch, SetStateAction, useState } from "react"
import { Button, Row } from "react-bootstrap"
import { toast } from "react-toastify"
import { LessonSubmissionResponse, MasteryTypes, PublishGradeProps, TopicSubmissionResponse } from "shared/types/moduleTypes"
import GradeInput from "../components/GradeInput"
import { TeacherAssignmentResponse } from "shared/types/teacherTypes"
import modules from "shared/routes/moduleRoutes"
import pureInquiry from "shared/routes/simulations/pureInquiry"
import styles from "../submissionTeacher.module.scss"
import ToolTip from "components/General/ToolTip"
import StandardsMasterySelection from "../components/StandardsMasterySelection"
import lessons from "shared/lessons"
import { useModel } from "@stem-sims/nexus"
import { CurriculumTopic } from "shared/types/curriculumTypes"

interface Props {
    /**
     * Props for topic assignment sidebar
     */
    topicSubmission?: TopicSubmissionResponse
    setTopicSubmission?: Dispatch<SetStateAction<TopicSubmissionResponse>>
    topic?: CurriculumTopic
    studentTopicId?: string

    lessonSubmission: LessonSubmissionResponse
    setLessonSubmission: Dispatch<SetStateAction<LessonSubmissionResponse>>
    lesson: any
    assignment: TeacherAssignmentResponse
    studentLessonId: string
    activeClass?: {
        standardId: string
    }
}

const TeacherLessonSidebar = ({ topicSubmission, setTopicSubmission, topic, studentTopicId, lessonSubmission, setLessonSubmission, lesson, assignment, studentLessonId, activeClass } : Props) => {
    const [submitting, setSubmitting] = useState(false)
    const [selectedStandards, setSelectedStandards] = useState<PublishGradeProps["standardsMastery"]>([])

    const { response: standardsResponse } = useModel({
        model: lessons.findStandardCorrelations,
        props: {
            lessonId: assignment.lessonId ?? undefined,
            standardId: activeClass?.standardId,
            core: true
        }
    })

    const standards = standardsResponse?.standards ?? []

    const publishLessonTopicGrade = async () => {
        const gradingTopic = topicSubmission && studentTopicId
        if (gradingTopic) {
            modules.publishTopicGrade({
                studentTopicId: studentTopicId,
                comment: topicSubmission?.comment ?? undefined,
                grade: topicSubmission?.grade 
            })
            .then(() => !lessonSubmission && toast.success("Published topic grade."))
            .catch(() => toast.error("Failed to publish topic grade, please try again."))
        }

        // if there is no lesson submission, stop here
        if (!lessonSubmission) return setSubmitting(false)

        let promise
        if (assignment.lessonVariety === "Guided") {
            promise = modules.publishLessonGrade({
                moduleName: lesson.module,
                lessonNumber: lesson?.number.toString(),
                lessonType: lesson.type,
                studentLessonId,
                grade: lessonSubmission.grade,
                comment: lessonSubmission.labComment ?? undefined,
                standardsMastery: selectedStandards
            })
        } else if (assignment.lessonVariety === "Pure Inquiry") {
            promise = pureInquiry.putGrade({
                pureInquiryId: studentLessonId,
                comment: lessonSubmission.labComment ?? undefined,
                grade: lessonSubmission.grade,
            })
        }
        return promise
            .then(() => {
                toast.success(gradingTopic ? "Published Grades." : "Published lesson grade.")
            }).catch(() => {
                toast.error("Failed to publish lesson grade, please try again.")
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    const updateTopicGrade = (grade: number) => {
        const newTopic = { ...topicSubmission }
        newTopic.grade = isNaN(grade) ? newTopic.grade : grade
        setTopicSubmission(newTopic)
    }

    const updateLessonGrade = (grade: number) => {
        const newLesson = { ...lessonSubmission }
        newLesson.grade = isNaN(grade) ? newLesson.grade : grade
        setLessonSubmission(newLesson)
    }

    const updateComment = (comment: string) => {
        if (topicSubmission) {
            const newTopic = { ...topicSubmission }
            newTopic.comment = comment
            setTopicSubmission(newTopic)
        } else {
            const newLesson = { ...lessonSubmission }
            newLesson.labComment = comment
            setLessonSubmission(newLesson)
        }
    }
    
    return <>
        <div className="text-center">
            <Button
                variant="theme"
                size="lg"
                className="mb-3 mt-3 fw-bold"
                disabled={submitting}
                onClick={() => {
                    setSubmitting(true)
                    publishLessonTopicGrade()
                }}
            >
                {!submitting && <span className={styles.submitGradeBtnText}>{topicSubmission ? `Submit Grades` : `Submit Lesson Grade`}</span>}
                {submitting && <span className={styles.submitGradeBtnText}>Submitting....</span>}
            </Button>
        </div>
        {lessonSubmission && <Label title="Time Taken">
            {
                Math.ceil((
                    new Date(lessonSubmission.completed).getTime()
                    -
                    new Date(lessonSubmission.started)
                        .getTime()
                ) / (1000 * 60)
                )
            } minutes
        </Label>}
        {topicSubmission &&
            <Label title="Topic Grade">
            <GradeInput
                className={styles.overallGradeInput}
                value={topicSubmission.grade}
                onChange={(grade) => {
                    updateTopicGrade(grade)
                }}
            />
            %
        </Label>
        }
        {lessonSubmission && <Label title="Lesson Grade">
            <GradeInput
                className={styles.overallGradeInput}
                value={lessonSubmission.grade}
                onChange={(grade) => {
                    updateLessonGrade(grade)
                }}
            />
            %
        </Label>}
        <Label title="Points">
            {(lessonSubmission?.points ?? 0) + (topicSubmission?.points ?? 0)}/{(lessonSubmission?.totalPoints ?? 0) + (topicSubmission?.totalPoints ?? 0)}
        </Label>
        {standards.length > 0 && <>
            <Row className="mt-4 mb-2">
                <strong>Standards Mastery</strong>
            </Row>
            <Row>
                {standards.map((standard) => <>
                    <ToolTip title={standard.description}>
                        <p className="pt-3">{standard.subtopicCode}</p>
                    </ToolTip>
                    <StandardsMasterySelection
                        initialValue={lessonSubmission.standardsMastery.find((s) => s.lessonStandardId === standard.id)?.mastery ?? null}
                        id={standard.id}
                        onChange={(newMastery: MasteryTypes | null) => {
                            let newStandards = [...selectedStandards]
                            const existingStandardIndex = newStandards.findIndex((newStandard) => {
                                return newStandard.lessonStandardId === standard.id
                            })

                            if (existingStandardIndex !== -1) {
                                if (newMastery !== null) {
                                    newStandards[existingStandardIndex].mastery = newMastery
                                }
                                else {
                                    //Remove the standard from list
                                    newStandards = [
                                        ...newStandards.slice(0, existingStandardIndex),
                                        ...newStandards.slice(existingStandardIndex + 1)
                                    ]
                                }
                            }
                            else {
                                newStandards.push({
                                    lessonStandardId: standard.id,
                                    mastery: newMastery
                                })
                            }
                            setSelectedStandards(newStandards)
                        }}
                    />
                </>)}
            </Row>
        </>}
        <hr />
        <i className="far fa-comment-dots pe-2 pb-2" />
        <strong>Comments</strong>
        <br />
        <textarea
            className={`${styles.commentTextbox} w-100 p-2`}
            defaultValue={topicSubmission?.comment ?? lessonSubmission?.labComment ?? ""}
            onChange={(e) => {
                updateComment(e.currentTarget.value)
            }}
        />
    </>
}

export default TeacherLessonSidebar
