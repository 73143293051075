import { parseFormData } from "helpers/formDataHelper"
import { LessonResponse } from "shared/lessons"
import modules from "shared/routes/moduleRoutes"
import pureInquiry from "shared/routes/simulations/pureInquiry"
import { DateString } from "shared/types/commonTypes"
import { Type } from "shared/types/moduleTypes"


type SaveGuidedLessonProps = {
    lesson: LessonResponse
    completed: boolean
    isTeacher: boolean
    lessonFormRef: any
    started: any
    prevSavedRef: any
    lessonSubmissionIdRef?: any
    submissionId?: string
}


export const saveGuidedLesson = async ({ lesson, completed, isTeacher, lessonFormRef, started, prevSavedRef, lessonSubmissionIdRef, submissionId }: SaveGuidedLessonProps): Promise<unknown> => {
    if (isTeacher) {
        return
    }

    const parameters = {
        moduleName: lesson.moduleName,
        lessonType: lesson.type,
        lessonNumber: lesson.number,
        submissionId: submissionId,
        formData: parseFormData(lessonFormRef.current),
        completed: completed,
    }
    if (prevSavedRef.current) {
        if (submissionId) {
            return modules.updateLesson({submissionId: submissionId, ...parameters})
        } else {
            return modules.updateLesson({studentLessonId: lessonSubmissionIdRef.current, ...parameters})
        }
    } else {
        const newLessonSubmissionId = await modules.submitLesson(parameters)
        if (newLessonSubmissionId.data) {
            prevSavedRef.current = true
            lessonSubmissionIdRef.current = newLessonSubmissionId.data // newly created studentLesson Id is returned unless there was no data to save
        }
    }
}

interface SaveTopicProps {
    topicId: string
    submissionId: string
    studentAnswers: any
    startTime: DateString
}

export const saveTopic = async ({ topicId, studentAnswers, submissionId, startTime } : SaveTopicProps) => {
    const parameters = {
        topicId,
        submissionId,
        startTime,
        postParams: studentAnswers,
    }
    await modules.submitTopic(parameters)
}

type SavePureInquiryLessonProps = {
    moduleName: string,
    type: Type,
    completed: boolean
    isTeacher: boolean
    questionAnswers: string[]
    tableAnswers: string[]
    started: any
    prevSavedRef: any
    submissionId?: string
}
export const savePureInquiryLesson = ({ moduleName, type, completed, isTeacher, questionAnswers, tableAnswers, started, prevSavedRef, submissionId }: SavePureInquiryLessonProps): Promise<unknown> => {
    if (isTeacher) {
        return
    }
    const parameters = {
        submissionId: submissionId,
        module: moduleName,
        type: type,
        started: started,
        questionAnswers: questionAnswers,
        tableAnswers: tableAnswers,
        completed: completed,
    }
    
    if (prevSavedRef.current) {
        return pureInquiry.put(parameters)
    } else {
        prevSavedRef.current = true
        return pureInquiry.post(parameters)
    }
}

export const answerLengths = {
    "Tier 1": 8,
    "Tier 2": 10,
    "AP Tier": 11
}

// number of milliseconds to wait in between trying to save lesson
export const saveLessonInterval = 5000
