import Pane from "components/Dashboards/General/Pane"
import FAIcon from "components/General/FAIcon"
import { Formik, Field } from "formik"
import React, { useState } from "react"
import { Row, Col, Form, Button, Modal } from "react-bootstrap"
import { PulseLoader } from "react-spinners"
import { updateSectionQuestion, createSectionQuestion, deleteSectionQuestion } from "shared/routes/curriculum/books"
import { CurriculumAnswer, CurriculumSection } from "shared/types/curriculumTypes"
import TextareaField from "./TextareaField"

import styles from "./editingStyles.module.scss"
const { selectable } = styles

interface Props {
    section: CurriculumSection
    loading: boolean
    refreshModel: () => Promise<unknown> | void
}

export default function EditingSectionQuestions({ section, loading, refreshModel }: Props) {
    const [questionDeleteId, setQuestionDeleteId] = useState(null)
    const [answerDeleteIndex, setAnswerDeleteIndex] = useState(null)

    return <>
        <h4>Questions</h4>
        {(!section.questions && loading) && <>
            <PulseLoader />
        </>}

        {(section.questions || !loading) && <>
            {section.questions.map((question, index) => (
                <Row key={question.id}>
                    <Col>
                        <Pane className={selectable}>
                            <Formik
                                initialValues={{
                                    question: question.question ?? "",
                                    correctAnswer: question.correctAnswer ?? "",
                                    answers: question.answers as Partial<CurriculumAnswer>[] ?? [],
                                }}
                                enableReinitialize
                                onSubmit={async (values) => {
                                    await updateSectionQuestion({
                                        questionId: question.id,
                                        question: values.question,
                                        answers: values.answers ?? undefined,
                                        correctAnswer: values.correctAnswer
                                    })
                                    refreshModel()
                                }}
                            >{({ handleSubmit, values }) => (<form onSubmit={handleSubmit}>
                                <Form.Group className="my-2" controlId="question">
                                    <Form.Label>Question</Form.Label>
                                    <Form.Control as={TextareaField} name="question" onBlur={() => handleSubmit()} />
                                </Form.Group>

                                <Form.Group className="my-2" controlId="correctAnswer">
                                    <Form.Label>Correct Answer</Form.Label>
                                    <Form.Control as={TextareaField} name="correctAnswer" onBlur={() => handleSubmit()} />
                                </Form.Group>

                                {question.type === "MultipleChoice" && <>
                                    {values.answers?.map((answer, answerIndex) => (<React.Fragment key={answer.id ?? `new-${answerIndex}`}>
                                        <hr />
                                        <Row>
                                            <Col>
                                                <Form.Group className="my-2" controlId={`answers[${answerIndex}].answer`}>
                                                    <Form.Label>Answer {answer.letter}</Form.Label>
                                                    <Form.Control as={Field} name={`answers[${answerIndex}].answer`} onBlur={() => handleSubmit()} />
                                                </Form.Group>

                                                <Form.Group className="my-2" controlId={`answers[${answerIndex}].explanation`} >
                                                    <Form.Label>Explanation</Form.Label>
                                                    <Form.Control as={TextareaField} name={`answers[${answerIndex}].explanation`} onBlur={() => handleSubmit()} />
                                                </Form.Group>
                                            </Col>

                                            <Col xs="auto" onClick={() => {
                                                setQuestionDeleteId(question.id)
                                                setAnswerDeleteIndex(answerIndex)
                                            }}>
                                                <Button variant="danger"><FAIcon iconName={"skull"} /></Button>
                                            </Col>
                                        </Row>
                                    </React.Fragment>))}

                                    <Row className="my-2">
                                        <Col>
                                            <Button onClick={async () => {
                                                await updateSectionQuestion({
                                                    questionId: question.id,
                                                    answers: values.answers.concat([{ answer: "" }])
                                                })
                                                refreshModel()
                                            }}><FAIcon iconName="plus" /> Answer</Button>
                                        </Col>
                                    </Row>
                                </>}
                            </form>)}</Formik>
                        </Pane>
                    </Col>

                    <Col xs="auto" onClick={async () => {
                        await updateSectionQuestion({
                            questionId: question.id,
                            order: index,
                        })
                        refreshModel()
                    }}>
                        <Button disabled={index === 0}><FAIcon iconName={"sort-up"} /></Button>
                    </Col>

                    <Col xs="auto" onClick={async () => {
                        await updateSectionQuestion({
                            questionId: question.id,
                            order: index + 2
                        })
                        refreshModel()
                    }}>
                        <Button disabled={index === section.questions?.length - 1}><FAIcon iconName={"sort-down"} /></Button>
                    </Col>

                    <Col xs="auto" onClick={() => setQuestionDeleteId(question.id)}>
                        <Button variant="danger"><FAIcon iconName={"skull"} /></Button>
                    </Col>
                </Row>
            ))}

            <Row className="my-2">
                <Col>
                    <Button onClick={async () => {
                        await createSectionQuestion({ sectionId: section?.id, type: "Text" })
                        refreshModel()
                    }}><FAIcon iconName="plus" />Free Response</Button>
                </Col>

                <Col>
                    <Button onClick={async () => {
                        await createSectionQuestion({ sectionId: section?.id, type: "MultipleChoice" })
                        refreshModel()
                    }}><FAIcon iconName="plus" />Multiple Choice</Button>
                </Col>
            </Row>
        </>}

        <Modal show={questionDeleteId && answerDeleteIndex == null} onHide={() => setQuestionDeleteId(null)}>
            <Modal.Header closeButton>
                Delete Question
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <h3>Are you sure you wish to delete this question?</h3>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="danger" onClick={async () => {
                    await deleteSectionQuestion({ questionId: questionDeleteId })
                    setQuestionDeleteId(null)
                    refreshModel()
                }}>Delete</Button>
            </Modal.Footer>
        </Modal>

        <Modal show={questionDeleteId && answerDeleteIndex != null} onHide={() => {
            setQuestionDeleteId(null)
            setAnswerDeleteIndex(null)
        }}>
            <Modal.Header closeButton>
                Delete Answer
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <h3>Are you sure you wish to delete this answer?</h3>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="danger" onClick={async () => {
                    const oldAnswers = section.questions.find(q => q.id === questionDeleteId).answers
                    const newAnswers = oldAnswers.slice(0, answerDeleteIndex).concat(oldAnswers.slice(answerDeleteIndex + 1))
                    await updateSectionQuestion({
                        questionId: questionDeleteId,
                        answers: newAnswers
                    })
                    setQuestionDeleteId(null)
                    setAnswerDeleteIndex(null)
                    refreshModel()
                }}>Delete</Button>
            </Modal.Footer>
        </Modal>
    </>
}
