import { Dispatch, SetStateAction } from "react"
import { TopicSubmissionResponse } from "shared/types/moduleTypes"
import LessonResponseItem from "./LessonResponseItem"
import AssessmentResponseItem from "./AssessmentResponseItem"
import useBoolean from "helpers/useBoolean"
import { CurriculumTopic } from "shared/types/curriculumTypes"
import modules from "shared/routes/moduleRoutes"

interface Props {
    topicSubmission: TopicSubmissionResponse
    setTopicSubmission: Dispatch<SetStateAction<any>>
    topic: CurriculumTopic
    studentTopicId: string
}

interface UpdateTopicQuestionProps {
    studentTopicAnswerId: string,
    grade?: number,
    comment?: string
}

const TeacherTopicResponses = ({ topicSubmission, setTopicSubmission, topic, studentTopicId } : Props) => {

    const sumTopicPoints = (topicSummary: TopicSubmissionResponse) => topicSummary.responses?.map(r => r.grade ?? 0).reduce((a, i) => a + i)
    const [expand, toggleExpand] = useBoolean(true)

    const updateTopicQuestion = ({ studentTopicAnswerId, grade = undefined, comment = undefined } : UpdateTopicQuestionProps) => {
        return modules.updateTopicQuestionGrade({ studentTopicAnswerId, studentTopicId, grade, comment })
    }

  return (
    <div>
        <div className="d-flex justify-content-center">
            <h3 className={`flex-grow-1 text-center fw-bold ${expand && `mb-4`}`}>Topic Responses</h3>
            <i role="button" className={`fas ${expand ? `fa-chevron-up` : `fa-chevron-down`} ms-auto`} onClick={toggleExpand} />
        </div>

        {expand && 
            <ol className="p-4">
                {topicSubmission.responses?.map((response, index) => {
                    return response.questionType === "Text" ? 
                        <LessonResponseItem
                            index={index}
                            responseType="topic"
                            submission={topicSubmission}
                            response={response}
                            setSubmission={setTopicSubmission}
                            updateQuestion={updateTopicQuestion}
                            sumPoints={sumTopicPoints}
                        /> : 
                        <AssessmentResponseItem
                            index={index}
                            response={response}
                        />
                })}
            </ol>
        }
    </div>
  )
}

export default TeacherTopicResponses
