/**
 * Parses an HTML Form into what it would submit as a POST request
 */
function parseFormData(form: HTMLFormElement) {
    const formData = {}
    for (const element of form?.elements ?? []) {
        if (element.tagName === "INPUT" && element.getAttribute("type") === "radio") {
            if ((element as HTMLInputElement).checked) {
                formData[element.getAttribute("name")] = (element as HTMLInputElement).value ?? null
            }
        } else if (element.tagName === "INPUT" || element.tagName === "TEXTAREA" || element.tagName === "SELECT") {
            if (element.getAttribute("type") === "checkbox") {
                // If it's a checkbox, skip instructions which should have format (question-1-{number})
                if (!element.getAttribute("name").includes("question-1-")) {
                    formData[element.getAttribute("name")] = (element as HTMLInputElement).value ?? null
                }
            } else {
                formData[element.getAttribute("name")] = (element as HTMLInputElement).value ?? null
                if (element.getAttribute("data-table-inputs") !== null) {
                    formData[element.getAttribute("data-table-inputs")] = (element as HTMLInputElement).value ?? null
                }
            }
        }
    }
    return formData
}

function formHasSufficientData(parsedData: any) : boolean {

    // Parsed data object needs to have 4 types of properties for example - question-1-1, table-1, table-1-1, s1-q1 started
    if (Object.keys(parsedData).length < 4) {
        return false
    }
    const tableData = {}
    for (const field of Object.keys(parsedData)) {
        if (!field.match(/^question-\d+-\d+$/) && !field.match(/^table-\d+$/) && !field.match(/^table-\d+-\d+$/) && !field.match(/^s\d+-q\d+$/) && field !== "started") {
            return false
        }

        if (field === "started") continue

        if (field.match(/^question-\d+-\d+$/) && !parsedData[field]) {
            return false
        }

        // Associate each table with an array of values. For exaple. {1: ["value", "value"], 2: []}
        if (field.match(/^table-\d+-\d+$/)) {
            const tableNumber = field.split('-')[1]
            if (!tableData[tableNumber]) {
                if  (parsedData[field]) {
                    tableData[tableNumber] = [parsedData[field]]
                } else {
                    tableData[tableNumber] = []
                }
            } else {
                if  (parsedData[field]) {
                    tableData[tableNumber].push(parsedData[field])
                }
            }
        }

        if (field.match(/^s\d+-q\d+$/) && !parsedData[field]) {
            return false
        }
    }
    const eachTableHasContent = checkEachTableContent(tableData)
    return eachTableHasContent
}

/**
 * Returns true if each table has at least one input. False otherwise.
 */
function checkEachTableContent(tableData: any) {
    for (let key in tableData) {
        if (tableData[key].length === 0) {
            return false
        }
    }
    return true
}

export {
    parseFormData,
    formHasSufficientData
}
