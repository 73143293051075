import { useContext } from "react"
import LessonSearch from "../Lessons/Search/Root"
import { useLocation, useNavigate } from "react-router"
import Button from "react-bootstrap/Button"
import { AuthContext } from "AuthContext"
import { LessonResponse } from "shared/lessons"

type SearchParams = {
    grades?: any,
    grade?: string[],
    category?: string
}
export default function SearchByPage() {
    const location = useLocation()
    const navigate = useNavigate()
    const authContext = useContext(AuthContext)
    const locationState = location.state as SearchParams

    const canMakeAssignments = authContext.isLoggedIn && !authContext.isStudent && authContext.trackingEnabled
    const onViewLesson = (lesson: LessonResponse) => {
        const lessonRoute = lesson.id ? `/simulations/lesson/?id=${lesson.id}` : `/simulations/pure-inquiry/?module=${lesson.moduleName}&type=${lesson.type}`
        navigate(lessonRoute)
    }
    const onViewAssessment = (lesson: LessonResponse) => {
        const assessmentRoute = `/simulations/assessment/?assessmentId=${lesson?.assessment.id}&module=${lesson.moduleName}&assessmentNumber=${lesson?.assessmentNumber}&type=${lesson.type}`
        navigate(assessmentRoute)
    }
    const onAssign = (lesson: LessonResponse, extraParams?) => {
        navigate("/dashboard/teacher/assignments/add", {
            state: {
                lessonId: lesson.id,
                lessonVariety: lesson.variety,
                module: lesson.moduleName,
                type: lesson.type,
                assessment: lesson.assessment,
                topicId: lesson.topicId,
                ...extraParams
            }
        })
    }
    const onAssignLesson = (lesson: LessonResponse) => {
        onAssign(lesson, { assignmentType: "lesson" })
    }
    const onAssignAssessment = (lesson: LessonResponse) => {
        onAssign(lesson, { assignmentType: "assessment" })
    }
    const onAssignTopic = (lesson: LessonResponse) => {
        onAssign(lesson, { assignmentType: "topic" })
    }
    const lessonBtn = (lesson: LessonResponse) => <>
        {canMakeAssignments ?
            <Button variant="theme float-end me-2" onClick={() => onAssignLesson(lesson)}>View/Assign Lesson</Button>
            :
            (<><Button variant="theme float-end" onClick={() => onViewLesson(lesson)}>View Lesson</Button>
            {lesson.variety !== "Pure Inquiry" && <Button variant="theme float-end me-2" onClick={() => onViewAssessment(lesson)}>View Assessment</Button>}</>)
        }
        
        {canMakeAssignments && lesson.assessmentNumber && <Button variant="theme float-end me-2" onClick={() => onAssignAssessment(lesson)}>View/Assign Assessment</Button>}
        {canMakeAssignments && lesson.topicId && <Button variant="theme float-end me-2" onClick={() => onAssignTopic(lesson)}>View/Assign Topic</Button>}
    </>

    return (
        <div>
            <LessonSearch
                onLessonSelection={lessonBtn}
                selectedCategoryInit={locationState?.category}
                gradesInit={locationState?.grades}
                selectedGradeInit={locationState?.grade}
                viewType="normal"
            />
        </div>
    )
}
